/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState, useEffect } from 'react';
import env from '../../../env';
import style from './atdwSearchMainStyle';
import { Loading } from 'components';
import utils from '../.././../utils';
import { AtdwSearchOption } from '../../atdw/atdwSearchOption/atdwSearchOption';
import { AtdwSearchResult } from '../../atdw/atdwSearchResult/atdwSearchResult';

export function AtdwSearchMain({atdwType, subTypes, autoSearch, hideSearch = 'off', numOfTileLines = '12', town = "ALL"}){

    const [atdwDataInfo, setAtdwDataInfo] = useState();
    const [searchPedding, setSearchPedding] = useState(false);
    const [txaTrigger, setTxaTrigger] = useState(0);
    const bp = utils.ui.getCurrentBreakpoint();
    const tilesPerLine = {xs:1, sm:1, md:3, lg:3, xl:3, xxl:3}
    const _numOfTileLines = hideSearch === 'on' ? parseInt(numOfTileLines) * tilesPerLine[bp] : 12;
    
    function addAtdw(){
        //const sliced = atdwDataInfo.reserveAtdwData.slice((atdwDataInfo.atdwData.length), (atdwDataInfo.atdwData.length + _numOfTileLines) - 1);
        const sliced = atdwDataInfo.reserveAtdwData.slice((atdwDataInfo.atdwData.length), (atdwDataInfo.atdwData.length + _numOfTileLines));
        sliced.forEach(el => {
            atdwDataInfo.atdwData.push(el);
        });
        setAtdwDataInfo({
            ...atdwDataInfo,
            atdwData : atdwDataInfo.atdwData
        });
    }

    function searechAtdw(searchOptionValue){

        setSearchPedding(true);
        const _atdwSubType = searchOptionValue.atdwSubType || [];
        const _atdwSearchByName = searchOptionValue.atdwSearchByName || '';
        const _atdwCheckIn = searchOptionValue.atdwCheckIn || '';
        const _atdwCheckOut = searchOptionValue.atdwCheckOut || '';
        const _atdwAdults = searchOptionValue.atdwAdults || '1';
        const _atdwKids = searchOptionValue.atdwKids || '0';
        const atdwTownItem = searchOptionValue.atdwTown || '';

        let _filteredData = searechAtdwFilter({
            atdwSubTypeItem : _atdwSubType,
            atdwSearchByNameItem : _atdwSearchByName,
            atdwTownItem : atdwTownItem,
            atdwCheckIn : _atdwCheckIn,
            atdwCheckOut : _atdwCheckOut,
            atdwDataItem : atdwDataInfo.atdwDataFromDB
        });

        if(atdwType === 'EVENT'){
            sortAtdw(_filteredData);
        }

        const _data = [];
        if(_filteredData.length > 0){
            for(var i = 0; i < _numOfTileLines; i++){
                _data.push(_filteredData[i]);
                if(i === (_filteredData.length -1)){
                    break;
                }
            }
        }


        if(atdwType === 'ACCOMM___' && (_atdwCheckIn !== "" && _atdwCheckIn !== null) && (_atdwCheckOut !== "" && _atdwCheckOut !== null)){
            console.log("TXA ::::: ->");
            setAtdwDataInfo({
                ...atdwDataInfo,
                reserveAtdwData : _filteredData,
                atdwData : _data,
                checkStartResult : true,
                atdwCheckIn : _atdwCheckIn,
                atdwCheckOut : _atdwCheckOut,
                atdwAdults : _atdwAdults,
                atdwKids : _atdwKids
            });
            setTxaTrigger(txaTrigger + 1);
        }
        else{
            setAtdwDataInfo({
                ...atdwDataInfo,
                reserveAtdwData : _filteredData,
                atdwData : _data,
                checkStartResult : true
            });
            setTimeout(() => {
                setSearchPedding(false);
            }, 1000);
        }
    }

    function sortAtdw(filteredData){
        
        console.log("filteredData", filteredData);
        
        filteredData.sort(function(a,b){

            let aTempDate = null;
            let bTempDate = null;

            if(a.additionalData.eventFrequency && a.additionalData.eventFrequency.length > 0){
                a.additionalData.eventFrequency.forEach(ela => {
                    if(aTempDate === null){
                        aTempDate = new Date(ela.frequencyStartDate);
                    }
                    else{
                        const aCurrentTempDate = new Date(ela.frequencyStartDate);
                        if(aCurrentTempDate <= aTempDate){
                            aTempDate = aCurrentTempDate;
                        }
                    }
                });
            }

            if(b.additionalData.eventFrequency && b.additionalData.eventFrequency.length > 0){
                b.additionalData.eventFrequency.forEach(elb => {
                    if(bTempDate === null){
                        bTempDate = new Date(elb.frequencyStartDate);
                    }
                    else{
                        const bCurrentTempDate = new Date(elb.frequencyStartDate);
                        if(bCurrentTempDate <= bTempDate){
                            bTempDate = bCurrentTempDate;
                        }
                    }
                });
            }
            
            aTempDate = aTempDate || new Date();
            bTempDate = bTempDate || new Date();

            return aTempDate - bTempDate;

        });
    }

    function searechAtdwFilter({atdwSubTypeItem, atdwSearchByNameItem, atdwTownItem, atdwCheckIn, atdwCheckOut, atdwDataItem}){
        
        const _atdwSubType = atdwSubTypeItem || [];
        const _atdwSearchByName = atdwSearchByNameItem || '';
        const _atdwTown = atdwTownItem || '';
        const _atdwCheckIn = atdwCheckIn || '';
        const _atdwCheckOut = atdwCheckOut || '';

        const _filteredData = atdwDataItem.filter((data) => {
            let returnResult = false;

            //1
            if((_atdwSubType.length > 0 && _atdwSubType[0] === '') || _atdwSubType.length === 0){
                returnResult = true;
            }
            else{
                var convertedAtdwSubType = _atdwSubType.toString().replace("HOTELMOTEL","HOTEL,MOTEL").replace("RESORTRETREAT","RESORT,RETREAT");
                for(var i in data.additionalData.PRODUCTTYPES){
                    returnResult = false;    
                    if(convertedAtdwSubType.includes(data.additionalData.PRODUCTTYPES[i].productTypeId)){
                        returnResult = true;
                        break;
                    }
                    // else{
                    //     returnResult = false;
                    //     break;
                    // }
                }
                //returnResult = true;
            }

            //2
            if(returnResult === true && (_atdwTown !== "" && _atdwTown !== null)){
                returnResult = false;
                if(data.town.toLowerCase().includes(_atdwTown.toLowerCase())){
                    returnResult = true;
                }
            }

            //3
            if(returnResult === true && (_atdwSearchByName !== "" && _atdwSearchByName !== null)){
                returnResult = false;
                if(data.productName.toLowerCase().includes(_atdwSearchByName.toLowerCase())){
                    returnResult = true;
                }
            }

            if(atdwType === 'EVENT' && returnResult === true && (_atdwCheckIn !== "" && _atdwCheckIn !== null) && (_atdwCheckOut !== "" && _atdwCheckOut !== null)){
                returnResult = false;
                const _checkIn = new Date(_atdwCheckIn);
                const _checkOut = new Date(_atdwCheckOut);
                const _eventFrequency = data.additionalData.eventFrequency;
                if(_eventFrequency && _eventFrequency.length > 0){
                    
                    for(let i in _eventFrequency){
                        const _startDate = new Date(_eventFrequency[i].frequencyStartDate)
                        //const _endDate = new Date(_eventFrequency[i].frequencyEndDate);
                        if(_checkIn <= _startDate && _checkOut >= _startDate){
                            returnResult = true;
                            break;
                        }
                    }
                }
            }
            return returnResult;
        });
        return _filteredData;
    }

    useEffect(() => {

        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            //const res = await fetch(env.apiBase + "/api/atdw/getproducts/" + atdwType, {signal:signal});
            const res = await fetch(env.apiBase + "/api/atdw/getproducts", {
                method : 'post',
                signal:signal,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    category : atdwType,
                    town : hideSearch === 'off' ? 'ALL' : town
                })
            });
            
            const data = await res.json();

            if(data && data.results && data.results.productResult){

                const _atdwSubType = subTypes && subTypes !== '' ? subTypes.split(",") : [];
                
                const _filteredData = searechAtdwFilter({
                    atdwSubTypeItem : _atdwSubType,
                    atdwDataItem : data.results.productResult.products,
                    atdwTownItem : town === 'ALL' ? '' : town
                });

                if(atdwType === 'EVENT'){
                    sortAtdw(_filteredData);
                }
                 
                const _data = [];
                if(_filteredData.length > 0){
                    for(var i = 0; i < _numOfTileLines; i++){
                        _data.push(_filteredData[i]);
                        if(i === (_filteredData.length -1)){
                            break;
                        }
                    }
                }

                let _autoSearch = autoSearch;
                if(hideSearch === 'on'){
                    _autoSearch = 'on'
                }

                let _checkStartResult = _autoSearch === 'on' ? true : false;

                setAtdwDataInfo({
                    atdwDataFromDB : data.results.productResult.products,
                    reserveAtdwData : _filteredData,
                    //atdwData : _data,
                    atdwData : _autoSearch === 'on' ? _data : [],
                    towns : data.results.productResult.tows,
                    subCategoies : _atdwSubType,
                    checkStartResult : _checkStartResult
                });
            }
        }

        fetchData();
        return function cleanup(){
            abortController.abort();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        if(txaTrigger === 0){
            return;
        }

        const abortController = new AbortController();
        //const signal = abortController.signal;

        const fetchData = async () => {
            //atdwDataInfo
            const txaList = [];
            atdwDataInfo.reserveAtdwData.forEach(el => {
                for(var i in el.additionalData.externalSystems){
                    if(el.additionalData.externalSystems[i].externalSystemCode === "TXA_DEFAULT"){
                        txaList.push(el.additionalData.externalSystems[i].externalSystemText);
                        break;
                    }
                }
            });

            // const res = await fetch(env.apiBase + "/api/atdw/gettxaproducts", {
            //     method : 'post',
            //     signal:signal,
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({
            //         //category : atdwType,
            //         //town : hideSearch === 'off' ? 'ALL' : town
            //     })
            // });
            
            // const data = await res.json();



        }

        fetchData();
        return function cleanup(){
            abortController.abort();
        }

        // eslint-disable-next-line
    },[txaTrigger]);

    return(
        atdwDataInfo ? 
        <Fragment> 
            {
                hideSearch === 'off' &&
                <AtdwSearchOption atdwType = {atdwType} searechAtdw = {searechAtdw} towns = {atdwDataInfo.towns} subCategoies = {atdwDataInfo.subCategoies} town = {town} />
            }
            {/* <AtdwSearchOption atdwType = {atdwType} searechAtdw = {searechAtdw} towns = {atdwDataInfo.towns} subCategoies = {atdwDataInfo.subCategoies} /> */}
            {
                searchPedding ? <Loading></Loading> :
                atdwDataInfo.atdwData.length > 0 ?
                <Fragment>
                    <div css={style.topBar}>
                        1 to {atdwDataInfo.atdwData.length} of {atdwDataInfo.reserveAtdwData.length}
                    </div>
                    <AtdwSearchResult atdwData = {atdwDataInfo.atdwData} />
                    <div css={style.bottomBar}>
                        1 to {atdwDataInfo.atdwData.length} of {atdwDataInfo.reserveAtdwData.length}
                    </div>
                    {
                        (atdwDataInfo.atdwData.length !== atdwDataInfo.reserveAtdwData.length && atdwDataInfo.atdwData.length > 0) &&
                        <div css={style.buttonContainer}>
                            <button css={style.button} className="btn btn-primary" onClick={addAtdw}>  
                                MORE
                            </button>
                        </div>
                    }
                </Fragment> :
                <Fragment>
                    {
                        atdwDataInfo.checkStartResult &&
                        <div css={style.noResultText}> Sorry, there are no available results. </div>
                    }
                </Fragment>
            }
        </Fragment>:<Loading></Loading>
    )
}