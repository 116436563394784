import React, { Fragment } from 'react';
import env from '../../env';
import { SiteLink, usePost, ErrorPopup, Loading, useRouter } from '../../components';
import { AtdwSearchResult } from '../atdw/atdwSearchResult/atdwSearchResult';
import utils from '../../utils';

export function SearchResult(props) {
  const { query } = useRouter();
  const post = usePost();
  const keyword = query.q;
  //console.log(keyword);

  React.useEffect(() => {
    post.send(env.apiBase + '/api/search/search', { keyword: keyword });
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  const data = post.response;
  const pages = data.pages;
  const atdws = data.atdws;

  console.log("pages :: ", pages);

  if (pages.length === 0 && atdws.length === 0) return (
    <Fragment>
      <h3 style={{marginTop: '2rem'}}>
        Showing Search Results For: <span>{keyword}</span>
      </h3>
      <div className="alert alert-danger" role="alert">
        No result found.
      </div>
    </Fragment>
  )
  return (
    <>
      <h3 style={{marginTop: '2rem'}}>
        Showing Search Results For: <span>{keyword}</span>
      </h3>
      {
        atdws.length > 0 &&
        <div>
          {/* <h3> ATDW </h3> */}
            <AtdwSearchResult atdwData = {atdws} />
        </div>
      }
      {pages.length > 0 &&
        <div>
          {/* <h3 style={{marginTop: '2rem'}}>Pages</h3> */}
          <PageList pages={pages} />
        </div>
      }
    </>
  );
}

function PageList({pages}) {
  return (
    <div className="pageTiles tiles">
      {pages.map((page, index) =>
        <Tile key={index} page={page}></Tile>
      )}
    </div>
  );
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  const colorTheme = (!page.colorTheme || page.colorTheme === 'default') ? 'orange' : page.colorTheme;

  return (
    <div className="pageTile tile">
      <SiteLink className="pageTile__content tile__content" to={disableLink ? '' : page.pageUrl}>
        <div className="tile__bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        
        <div className={`pageTile_details_container ${colorTheme}`}>
        
        <div className="pageTile_details tile__details">
          <div className="pageTile__title new-style">
            {page.pageTitle}
          </div>
          <hr className="pageTile__dot__style" />
          <div className="pageTile_desc">
            {page.tileDesc || page.description}
          </div>
        </div>

        <div className="pageTile__bottom__img">
          <img src="/assets/flow-device-transparent.png" style={{width:'100%'}} alt="wave"/>
        </div>

        </div>

      </SiteLink>
    </div>
  )
}

// function PageList({pages}) {
//   return (
//     <div className="pageList row">
//       {pages.map((page, index) =>
//         <div className="pageListItem col-md-6 col-lg-4" key={index}>
//           <div className="pageListItem__inner">
//             <SiteLink className="pageListItem__title" to={page.pageUrl}>{page.pageTitle}</SiteLink>
//             <div className="pageListItem__highlight" dangerouslySetInnerHTML={{ __html: page.searchHighlight }}></div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// function AtdwList(){
//   return(
//     <div>sdfsklfjsdlkfjsdlkfj</div>
//   )
// }