//import { variables, mq } from '../../../cssInJs';

export default {
    container : {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '-10px',
        marginRight: '-10px'
    },
    imgItemContainer  : {
        padding: '10px',
        maxWidth: '16.6%',
        flexBasis: '16.6%',
        flex: '0 0 50%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    imgItem : {
        //paddingTop : ['150px']
        paddingBottom: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }
}