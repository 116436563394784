/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, {useLayoutEffect } from "react";
import utils from "utils";
import { getConsentFormModel } from "./consentFormModel";
import { usePost, Button, ErrorMessage } from "../../../../components";
import { useForm } from "../../../../form";
import cmsUtils from "../../cmsUtils";
import style from './consentFormStyle';
import env from '../../../../env';

export function ConsentForm(props) {
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames(
        "cms_item",
        "consent-form",
        item.cssClass || item.anchorName || ""
    );

    const emailReceiver = cmsUtils.payload(item, 'EmailReceiver');
    //const fileAttachment = cmsUtils.payload(item, 'FileAttachment') || "false";
    const mailchimpApiKey = cmsUtils.payload(item, 'MailchimpApiKey');
    const mailchimpAudienceKey = cmsUtils.payload(item, 'MailchimpAudienceKey');

    const model = getConsentFormModel({});

    const form = useForm(model, { usePlaceholder: true });
    const [sendingStatus, setSendingStatus] = React.useState(null);
    const [mailChimpError, setMailChimpError] = React.useState();
    const post = usePost();

    const mailChimpErrorMemo = React.useMemo(() => {
        return mailChimpError;
    }, [mailChimpError]);

    function onSubmit(e) {
        setMailChimpError(null);
        form.validateForm(e, () => {
            //const recaptchaValue = recaptchaRef.current.getValue();
            //const value = { ...form.getValue(), emailReceiver: emailReceiver, recaptchaValue : recaptchaValue };
            const value = {
                ...form.getValue(),
                emailReceiver: emailReceiver,
                mailchimpApiKey: mailchimpApiKey,
                mailchimpAudienceKey: mailchimpAudienceKey,
            };
            
            var files = form.getFiles();
            var formData = new FormData();
            formData.append("jsonData", JSON.stringify(value));
            files.forEach((file) => {
                (file.files || []).forEach((uploadFile) => {
                    formData.append(file.name, uploadFile);
                });
            });
            //post.send(env.apiBase + "/api/contact/send", value);
            post.sendFormData(env.apiBase + "/api/contact/consentform", formData);
            setSendingStatus("pending");
        });
        console.log("submitting");
    }

    if (post.done() && sendingStatus === "pending") {
        //var value = form.getValue();
        //gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} )
        form.reset(model);
        setSendingStatus(null);

        if (
            post.response.results &&
            post.response.results.MailChimpError &&
            post.response.results.MailChimpError !== ""
        ) {
            setMailChimpError(
                "Mail Chimp Error : " + post.response.results.MailChimpError
            );
        }
    }

    const render = (name) => form.renderControl(name, null);

    useLayoutEffect(() => {
        document.querySelector('div.accept__checkbox label span.req').style.display = 'none';
        // let _a = document.createElement('a');
        // _a.innerHTML = "Terms & Conditions";
        // _a.href = '/';
        // document.querySelector('div.accept__checkbox label').appendChild(_a);
    });

    return (
        <div
            className={cssClass}
            data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
            id={item.anchorName}
        >
            <div className="container contact-wrapper" id="consentForm">
                <form css={style.formStyle} className="contactForm">
                    
                    <h4> CONTACT INFORMATION </h4>
                    
                    <div className="form-row">
                        <div className="col-md-6 mb-2">{render("organisation")}</div>
                        <div className="col-md-6 mb-2">{render("postalAddress")}</div>
                    </div>
                    
                    <div className="form-row">
                        <div className="col-md-4 mb-2">{render("contactPerson")}</div>
                        <div className="col-md-4 mb-2">{render("contactNumber")}</div>
                        <div className="col-md-4 mb-2">{render("email")}</div>
                    </div>

                    <hr />

                    <div css={style.titleStyle}>
                        <h4> INTENDED USE OF LOGO </h4> <span>(please tick all applicable)</span>
                    </div>
                    <div className="form-row">
                        <div css={style.colStyle} className="col-md-12 mb-2">{render("intendedUse")}</div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-2">{render("intendedUseEnquiry")}</div>
                    </div>
                    <hr />
                    <div css={style.titleStyle}>
                        <h4> MEMBERSHIP DETAILS </h4> <span>(please tick all applicable)</span>
                    </div>
                    <div className="form-row">
                        <div css={style.colStyle} className="col-md-12 mb-2">{render("memberShipDetails")}</div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-2">{render("memberShipDetailsEnquiry")}</div>
                    </div>
                    <hr />
                    
                    <div className="form-row">
                        <div className="col-md-12 mb-2 accept__checkbox">
                            {render("accept")}
                            {/* <SiteLink to="/">
                                Terms & Conditions
                            </SiteLink> */}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 mb-2">
                            <div className="actions" css={style.actions}>
                                <Button onClick={onSubmit} status={post.status}>
                                    {" "}
                                    Submit{" "}
                                </Button>
                                <ErrorMessage
                                    errors={form.errors}
                                    summaryMessage="Please review the errors."
                                />
                                <ErrorMessage errors={post.errors} />
                                <ErrorMessage errors={mailChimpErrorMemo} />
                                {post.done() && (
                                    <span
                                        style={{
                                            marginLeft: "1.5rem",
                                            color: "red",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Successfully sent!
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}