/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import { Item } from '../../../../cms/items/item';
import utils from '../../../../utils';
//import { Fragment } from 'react';
import { getPanelStyle } from './panelStyle';

export function Panel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  
  //console.log(cmsUtils.hasItemInPageDataByItemTypeCode(cmsOption.pageData, 'DestinationOperators'))

  const bgColor = cmsUtils.payload(item, 'BgColor');
  const waveColor = cmsUtils.payload(item, 'BgColor') || "#fdf8e6";
  const wave = cmsUtils.payload(item, 'Wave') || 'none';
  //const textColorTheme = cmsUtils.payload(item, 'TextColorTheme');
  const imageUrl = cmsUtils.payload(item, 'ImageUrl');
  const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
  const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';

  const specialStyle = cmsUtils.payload(item, 'SpecialStyle') || 'none';
  const specialStyleColorTop = cmsUtils.payload(item, 'SpecialStyleColorTop') || '#fdf8e6';
  const specialStyleColorCenter = cmsUtils.payload(item, 'SpecialStyleColorCenter') || '#fdf8e6';
  const specialStyleColorBottom = cmsUtils.payload(item, 'SpecialStyleColorBottom') || '#fdf8e6';

  const s = getPanelStyle();

  let _topWaveColor = (waveColor === "transparent") ? "#fdf8e6" : waveColor;
  let _bottomWaveColor = (waveColor === "transparent") ? "#fdf8e6" : waveColor;

  if(specialStyle === 'threeColorStyle'){
    _topWaveColor = specialStyleColorTop;
    _bottomWaveColor = specialStyleColorBottom;
  }
  else if(specialStyle === 'twoColorStyle'){
    _topWaveColor = specialStyleColorTop;
    _bottomWaveColor = specialStyleColorBottom;
  }
  
  //console.log("waveColor sdfjsdlkfjsdkldjfskdsjflkdsj ", waveColor)

  let bgUrl = null;
  if (imageUrl)
    bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

  const cssClass = utils.classNames('cms_item', 'contentPanel', 'contentPanel--' + bgTheme, item.cssClass || item.anchorName || '');

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  return (
    <section className={cssClass} style={{
      backgroundColor: `${bgColor}`,
      backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
    }} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      
      <div className="contentPanel__bg"></div>
      {
        specialStyle === 'threeColorStyle' &&
        <div css={s.hoverPanel}>
          <div className={`top`} css={s.hoverPanelTop3} style={{backgroundColor: specialStyleColorTop}}></div>
          <div className={`center`} css={s.hoverPanelCenter3} style={{backgroundColor: specialStyleColorCenter}}>
            <div className={`wave-animation-top`} css={s.waveColor} style={{color: specialStyleColorCenter}}>
              <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 25 H 0 V 0"></path></g></svg>
            </div>
            <div className={`wave-animation-bottom`} css={s.waveColor} style={{color: specialStyleColorCenter}}>
              <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5"></path></g></svg>
            </div>
          </div>
          <div className={`bottom`} css={s.hoverPanelBottom3} style={{backgroundColor: specialStyleColorBottom}}></div>
        </div>
      }

      {
        specialStyle === 'twoColorStyle' &&
        <div css={s.hoverPanel}>
          <div className={`top`} css={s.hoverPanelTop2} style={{backgroundColor: specialStyleColorTop}}>
            
            <div className={`wave-animation-top`} css={s.waveColor} style={{color: specialStyleColorTop}}>
              <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 25 H 0 V 0"></path></g></svg>
            </div>
            
            <div className={`wave-animation-bottom`} css={s.waveColor} style={{color: specialStyleColorTop}}>
              <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5"></path></g></svg>
            </div>
          </div>
          
          <div className={`bottom`} css={s.hoverPanelBottom2} style={{backgroundColor: specialStyleColorBottom}}></div>
        </div>
      }
      
      {
        (wave === 'top' || wave === 'both') &&
        <div className="wave-animation-top" style={{color:_topWaveColor}}>
          <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 25 H 0 V 0"></path></g></svg>
        </div>
      }
      
      <div className="container">{subItemsElts}</div>

      {
        (wave === 'bottom' || wave === 'both') &&
        <div className="wave-animation-bottom" style={{color:_bottomWaveColor}}>
          <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5"></path></g></svg>
        </div>
      }

    </section>
  );
}
