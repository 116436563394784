export default {
    
    atdwTiles : {
        display: 'flex',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        //justifyContent: 'space-between'
        justifyContent: 'start',
        marginLeft: '-0.7rem',
        marginRight: '-0.7rem'
    }
    
}