/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './itineraryStyle';
import { Fragment, useEffect, useState, useRef } from 'react';
import { useRouter, Loading } from '../../../components';
import env from '../../../env';
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { MdArrowDropDownCircle } from "react-icons/md";
import { isMobile } from '../../siteUtils';

export function Itinerary() {

    const slug = useRouter().query.slug;
    const [itineraryData, setItineraryData] = useState();

    useEffect(() => {

        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/itinerary/getitinerary/" + slug, { signal: signal });
            const data = await res.json();
            setItineraryData(data);
        };

        fetchData();

        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, []);

    if (!itineraryData) {
        return <Loading />
    }

    return (
        <ItineraryLayout itineraryData={itineraryData} />
    )
}

function ItineraryLayout({ itineraryData }) {

    const mapItem = useRef(null);
    const layoutRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(true);

    const [markersData, setMarkersData] = useState();
    const [markersDataD, setMarkersDataD] = useState();
    const [mapData, setMapData] = useState();

    const [currentSubItem, setCurrentSubItem] = useState(itineraryData.subItems[0]);
    const [currentDayItem, setCurrentDayItem] = useState();
    const [directionsInfo, setDirectionsInfo] = useState();

    function openMarkerItem(item) {

        setMapOnStartItem(markersData);
        setTimeout(() => {
            for (let i in markersData) {
                if (markersData[i].id === item.id) {
                    markersData[i].setMap(mapData);
                    window.google.maps.event.trigger(markersData[i], 'mouseover');
                    break;
                }
            }
        }, 100);
    }

    function closeMarkerItem(item) {
        //markersData[0].setMap(null);
        setMapOnStartItem(markersData);
    }

    function setMapOnStartItem(markers) {
        for (let i = 0; i < markers.length; i++) {
            // if (markers[i].title === '0') {
            //     markers[i].setMap(map);
            // }
            // else {
            //     markers[i].setMap(null);
            // }
            markers[i].setMap(null);
        }
    }

    function updateCurrentSubItem(subItem) {
        setCurrentSubItem(subItem);
    }

    function updateCurrentDayItem(dayItem) {
        setCurrentDayItem(dayItem);
        setMenuOpen(false);
        //setMapOnStartItem(markersData);
    }

    useEffect(() => {

        let checkScript = true;
        const script = document.createElement('script');

        if (window['google'] === undefined) {
            checkScript = false;
        }
        else {
            if (window['google'].maps === undefined) {
                checkScript = true;
            }
        }

        if (!checkScript) {
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY';
            script.async = true;
            document.body.appendChild(script);
            setTimeout(() => {
                setupMap();
            }, 500);
        }
        else {
            setupMap();
        }

        function setupMap() {
            let center = { lat: -34.17547561936846, lng: 140.02757156230592 };
            let zoom = 9;
            let map = new window.google.maps.Map(mapItem.current, {
                center: center,
                zoom: zoom,
                mapId: "77731b8119077e37",
            });

            let directionsService = new window.google.maps.DirectionsService();
            //let directionsRenderer = new window.google.maps.DirectionsRenderer();
            let directionsRenderer = new window.google.maps.DirectionsRenderer({
                polylineOptions: {
                    strokeColor: "red",
                    strokeOpacity: 0.7,
                    strokeWeight: 3,
                },
                suppressMarkers: true
            });

            directionsRenderer.setMap(map);

            setDirectionsInfo({
                directionsService: directionsService,
                directionsRenderer: directionsRenderer
            });
            setMapData(map);
        }

        const resized = (() => {
            const offset = window.innerWidth >= 992 ? 220 : 100;
            layoutRef.current.style.height = (window.innerHeight - offset) + "px";
        });
        resized();
        window.addEventListener('resize', resized);

        return function cleanup() {

            if (!checkScript) {
                document.body.removeChild(script);
            }

            if (typeof window !== `undefined`) {
                window.removeEventListener('resize', resized)
            }
        }

        // eslint-disable-next-line
    }, [currentSubItem]);

    useEffect(() => {

        if (!currentDayItem || currentDayItem === null) {
            return
        }

        if (markersDataD && markersDataD.length > 0) {
            for (let i = 0; i < markersDataD.length; i++) {
                // if (markers[i].title === '0') {
                //     markers[i].setMap(map);
                // }
                // else {
                //     markers[i].setMap(null);
                // }
                markersDataD[i].setMap(null);
            }
        }

        if (markersData && markersData.length > 0) {
            setMapOnStartItem(markersData);
        }

        const locationItems = currentDayItem.locationItems;
        //const itineraryMapItems = itineraryData.itineraryMapItems;
        let markers = [];

        locationItems.forEach(ele => {
            ele.detailItems.forEach(el => {
                let geoCode = {
                    lat: Number(el.lat),
                    lng: Number(el.lng)
                };
                let marker = new window.google.maps.Marker({
                    position: { lat: geoCode.lat, lng: geoCode.lng },
                    map: mapData,
                    title: el.title,
                    id: el.id,
                    zIndex: 100
                });
                let infowindow = new window.google.maps.InfoWindow({
                    content:
                        '<div class="info__window">' +
                        '<div class="info__title">' + el.detailItemTitle + '</div>' +
                        '<div class="info__img">' +
                        '<img src="' + el.thumbnail + '" alt="img" width="100%" >' +
                        '</div>' +
                        '</div>'
                });

                infowindow.addListener('closeclick', function(){
                    setMapOnStartItem(markers);
                });

                marker.addListener('mouseover', function () {
                    infowindow.open(mapData, marker);
                });
                marker.addListener('mouseout', function () {
                    infowindow.close();
                });
                markers.push(marker);
            });
        });

        setMapOnStartItem(markers);

        setTimeout(() => {
            calculateAndDisplayRoute(directionsInfo.directionsService, directionsInfo.directionsRenderer, locationItems, mapData);
        }, 100);

        setMarkersData(markers);

        function calculateAndDisplayRoute(directionsService, directionsRenderer, locationItems, map) {

            let markers = [];

            const origin = locationItems[0];
            const destination = locationItems[0].endDestination && locationItems[0].endDestination !== '' ? { location: locationItems[0].endDestination } : locationItems[locationItems.length - 1];

            const _waypts = locationItems.slice(1, -1);
            const waypts = [];

            _waypts.forEach(el => {
                waypts.push({
                    location: el.location,
                    stopover: true
                });
            });

            directionsService.route({
                origin: origin.location,
                destination: destination.location,
                optimizeWaypoints: true,
                waypoints: waypts,
                //waypoints: null,
                travelMode: 'DRIVING',
            }).then((response) => {
                directionsRenderer.setDirections(response);

                // setTimeout(() => {
                //     map.setZoom(9);
                // }, 1000);
                let mapCount = 1;
                let lastItme;

                for (let i = 0; i < response.routes[0].legs.length; i++) {
                    const leg = response.routes[0].legs[i];
                    // if (i === 0) {
                    //     let marker = new window.google.maps.Marker({
                    //         position: leg.start_location,
                    //         map: map,
                    //         icon: "/assets/pin/pin-" + (i + 1) + ".png",
                    //         title: "1"
                    //     });
                    //     markers.push(marker);

                    //     let marker2 = new window.google.maps.Marker({
                    //         position: leg.end_location,
                    //         map: map,
                    //         icon: "/assets/pin/pin-" + (locationItems.length) + ".png",
                    //         title: "2"
                    //     });
                    //     markers.push(marker2);
                    // }
                    // else {
                    //     let marker = new window.google.maps.Marker({
                    //         position: leg.end_location,
                    //         map: map,
                    //         icon: "/assets/pin/pin-" + (i + 1) + ".png",
                    //         title: "2"
                    //     });
                    //     markers.push(marker);
                    // }

                    let marker = new window.google.maps.Marker({
                        position: leg.start_location,
                        map: map,
                        icon: "/assets/pin/pin-" + mapCount + ".png",
                        title: "1",
                        width: '30px'
                    });
                    lastItme = leg;
                    markers.push(marker);
                    mapCount++;
                }

                let marker1 = new window.google.maps.Marker({
                    position: lastItme.end_location,
                    map: map,
                    icon: "/assets/pin/pin-" + mapCount + ".png",
                    title: "1"
                });

                markers.push(marker1);

                setMarkersDataD(markers);
            }).catch((e) => window.alert("Directions request failed due to"));
        }

        // eslint-disable-next-line
    }, [currentDayItem]);

    // useEffect(() => {
    //     let checkScript = true;
    //     const script = document.createElement('script');

    //     if (window['google'] === undefined) {
    //         checkScript = false;
    //     }
    //     else {
    //         if (window['google'].maps === undefined) {
    //             checkScript = true;
    //         }
    //     }

    //     if (!checkScript) {
    //         script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY';
    //         script.async = true;
    //         document.body.appendChild(script);
    //         setTimeout(() => {
    //             setupMap();
    //         }, 1000);
    //     }
    //     else {
    //         setupMap();
    //     }

    //     const itineraryMapItems = itineraryData.itineraryMapItems;
    //     let markers = [];

    //     function setupMap() {
    //         let center = { lat: -34.17547561936846, lng: 140.02757156230592 };
    //         let zoom = 9;
    //         let map = new window.google.maps.Map(mapItem.current, {
    //             center: center,
    //             zoom: zoom,
    //             mapId: "77731b8119077e37",
    //         });

    //         itineraryMapItems.forEach(el => {

    //             console.log("sdfdsfdsf", el);

    //             let geoCode = {
    //                 lat: Number(el.lat),
    //                 lng: Number(el.lng)
    //             };
    //             let marker = new window.google.maps.Marker({
    //                 position: { lat: geoCode.lat, lng: geoCode.lng },
    //                 map: map,
    //                 title: el.title,
    //                 id: el.id
    //             });
    //             let infowindow = new window.google.maps.InfoWindow({
    //                 content:
    //                     '<div class="info__window">' +
    //                         '<div class="info__title">' + el.title + '</div>' +
    //                         '<div class="info__img">' + 
    //                             '<img src="' + el.thumbnail + '" alt="img" width="100%" >' + 
    //                         '</div>' +
    //                     '</div>'
    //             });
    //             marker.addListener('mouseover', function () {
    //                 infowindow.open(map, marker);
    //             });
    //             marker.addListener('mouseout', function () {
    //                 infowindow.close();
    //             });
    //             markers.push(marker);
    //         });

    //         setMapOnStartItem(markers);

    //         let directionsService = new window.google.maps.DirectionsService();
    //         //let directionsRenderer = new window.google.maps.DirectionsRenderer();
    //         let directionsRenderer = new window.google.maps.DirectionsRenderer({ suppressMarkers: true });
    //         const locationItems = itineraryData.itineraryViewItems;
    //         //let stepDisplay = new google.maps.InfoWindow();

    //         directionsRenderer.setMap(map);
    //         setTimeout(() => {
    //             calculateAndDisplayRoute(directionsService, directionsRenderer, locationItems, map);

    //         }, 500);

    //         setMarkersData(markers);
    //         setMapData(map);
    //     }

    //     function calculateAndDisplayRoute(directionsService, directionsRenderer, locationItems, map) {

    //         const origin = locationItems[0];
    //         const destination = locationItems[locationItems.length - 1];
    //         const _waypts = locationItems.slice(1, -1);
    //         const waypts = [];

    //         _waypts.forEach(el => {
    //             waypts.push({
    //                 location: el.location,
    //                 stopover: true
    //             });
    //         });

    //         directionsService.route({
    //             origin: origin.location,
    //             destination: destination.location,
    //             optimizeWaypoints: true,
    //             waypoints: waypts,
    //             travelMode: 'DRIVING',
    //         }).then((response) => {
    //             directionsRenderer.setDirections(response);

    //             // setTimeout(() => {
    //             //     map.setZoom(9);
    //             // }, 1000);

    //             for (let i = 0; i < response.routes[0].legs.length; i++) {
    //                 const leg = response.routes[0].legs[i];
    //                 if (i === 0) {
    //                     new window.google.maps.Marker({
    //                         position: leg.start_location,
    //                         map: map,
    //                         icon: "http://maps.google.com/mapfiles/ms/micons/green.png",
    //                         title: "1"
    //                     });
    //                     new window.google.maps.Marker({
    //                         position: leg.end_location,
    //                         map: map,
    //                         icon: "http://maps.google.com/mapfiles/ms/micons/green.png",
    //                         title: "2"
    //                     });
    //                 }
    //                 else {
    //                     new window.google.maps.Marker({
    //                         position: leg.end_location,
    //                         map: map,
    //                         icon: "http://maps.google.com/mapfiles/ms/micons/green.png",
    //                         title: "2"
    //                     });
    //                 }
    //             }

    //         }).catch((e) => window.alert("Directions request failed due to"));
    //     }

    //     const resized = (() => {
    //         const offset = window.innerWidth >= 992 ? 220 : 100;
    //         layoutRef.current.style.height = (window.innerHeight - offset) + "px";
    //     });
    //     resized();
    //     window.addEventListener('resize', resized);

    //     return function cleanup() {
    //         if (!checkScript) {
    //             document.body.removeChild(script);
    //         }

    //         if (typeof window !== `undefined`) {
    //             window.removeEventListener('resize', resized)
    //         }
    //     }

    //     // eslint-disable-next-line
    // }, []);

    return (
        <Fragment>
            <div className='itinerary__menu__icon' css={style.itinerary__menu__icon}> <AiOutlineMenuUnfold onClick={() => {
                setMenuOpen(!menuOpen);
            }} /></div>

            <div ref={layoutRef} className={`itinerary__layout ${menuOpen ? "open" : "close"}`} css={style.itinerary__layout}>
                <div className={`itinerary__side__panel ${menuOpen ? "open" : "close"}`} css={style.itinerary__side__panel}>
                    <ItineraryView
                        itineraryData={itineraryData}
                        openMarkerItem={openMarkerItem}
                        closeMarkerItem={closeMarkerItem}
                        currentSubItem={currentSubItem}
                        setCurrentSubItem={setCurrentSubItem}
                        currentDayItem={currentDayItem}
                        setCurrentDayItem={setCurrentDayItem}
                        updateCurrentSubItem={updateCurrentSubItem}
                        updateCurrentDayItem={updateCurrentDayItem} />
                </div>
                <div className={`itinerary__side___close__panel ${menuOpen ? "open" : "close"}`} css={style.itinerary__side___close__panel} onClick={()=>{
                    setMenuOpen(false);
                }}></div>
                <div className={`itinerary__main__panel`} css={style.itinerary__main__panel}>
                    <div ref={mapItem} style={{ height: "100%" }}></div>
                </div>
            </div>
        </Fragment>
    )
}

function ItineraryView({ itineraryData, openMarkerItem, closeMarkerItem, currentSubItem, currentDayItem, updateCurrentSubItem, updateCurrentDayItem }) {

    const title = itineraryData.mainItemTitle;
    const des = itineraryData.mainItemDes;
    const subItems = itineraryData.subItems

    return (
        <div id='itinerary__view__item__container' className='itinerary__view__item__container' css={style.itinerary__view__item__container}>
            <div className='itinerary__view__item__title' css={style.itinerary__view__item__title}>
                {title}
            </div>
            <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                <img src="/assets/logos/riverland-logo-trip-planner.png" width='100%' alt='img' />
            </div>
            <div className='itinerary__view__item__des' css={style.itinerary__view__item__des} dangerouslySetInnerHTML={{ __html: des }}></div>
            <hr className='hr__style' css={style.itinerary__hr__style} />

            <div className='itinerary__sub__items' css={style.itinerary__sub__items}>
                {
                    subItems.map((item, index) => <div className={`itinerary__sub__item ${currentSubItem.subItemId === item.subItemId ? 'selected' : 'unselected'}`} key={index}>
                        <div className={`itinerary__sub__item__inner`} onClick={() => {
                            updateCurrentSubItem(subItems[index]);
                            updateCurrentDayItem(null);
                        }}>
                            <div className='itinerary__sub__item__inner__inner'>
                                {item.subItemTitle}
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <DayItems dayItems={currentSubItem.dayItems} updateCurrentDayItem={updateCurrentDayItem} currentDayItem={currentDayItem} openMarkerItem={openMarkerItem} closeMarkerItem={closeMarkerItem} />
        </div>
    )
}

function DayItems({ dayItems, updateCurrentDayItem, currentDayItem, openMarkerItem, closeMarkerItem }) {
    return (
        <div className='itinerary__day__container' css={style.itinerary__day__container}>
            {
                dayItems && dayItems.length > 0 ?
                    <div>
                        {
                            dayItems.map((item, index) =>
                                <div key={index}>
                                    <div className='itinerary__day__item__title' css={style.itinerary__day__item__title} onClick={() => {
                                        updateCurrentDayItem(item);
                                    }}>
                                        {item.dayItemTitle}
                                        <div className='itinerary__day__item__title__svg'> <MdArrowDropDownCircle /> </div>
                                    </div>
                                    {
                                        currentDayItem && currentDayItem.dayItemId === item.dayItemId &&
                                        <LocationItems locationItems={item.locationItems} openMarkerItem={openMarkerItem} closeMarkerItem={closeMarkerItem} />
                                    }
                                </div>)
                        }
                    </div> : <div> No Iitems </div>
            }
        </div>
    )
}

function LocationItems({ locationItems, openMarkerItem, closeMarkerItem }) {
    return (
        <div>
            {
                locationItems.map((item, index) =>
                    <div key={index}>

                        <div className={`itinerary__item__view ${locationItems.length === 1 ? 'one__day__trip' : 'none__one__day__trip'}`} css={style.itinerary__item__view}>
                            <div className='itinerary__item__view__wrapper' css={style.itinerary__item__view__wrapper}>
                                <div className='itinerary__item__view__index' css={style.itinerary__item__view__index}>
                                    <div className='itinerary__view__index__item' css={style.itinerary__view__index__item}>
                                        <div className='itinerary__view__index__item__inner'>
                                            {
                                                locationItems.length === 1 ? '1' : item.seq
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='itinerary__item__view__detail' css={style.itinerary__item__view__detail}>
                                    <div className='itinerary__item__view__detail__title'>
                                        {
                                            locationItems.length === 1 ? 'Start' : 'Day ' + item.seq
                                        }
                                    </div>
                                    <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                </div>
                            </div>
                            {
                                item.detailItems.map((itemN, index) => <ItineraryItemDetailView key={index} item={itemN} openMarkerItem={openMarkerItem} closeMarkerItem={closeMarkerItem} />)
                            }

                        </div>
                        {
                            item.endDestination && item.endDestination !== '' &&
                            <div className='itinerary__item__view end__destination' css={style.itinerary__item__view}>
                                <div className='itinerary__item__view__wrapper' css={style.itinerary__item__view__wrapper}>
                                    <div className='itinerary__item__view__index' css={style.itinerary__item__view__index}>
                                        <div className='itinerary__view__index__item' css={style.itinerary__view__index__item}>
                                            <div className='itinerary__view__index__item__inner'>2</div>
                                        </div>
                                    </div>
                                    <div className='itinerary__item__view__detail' css={style.itinerary__item__view__detail}>
                                        <div className='itinerary__item__view__detail__title'> End </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                )
            }
        </div>
    )
}

function ItineraryItemDetailView({ item, openMarkerItem, closeMarkerItem }) {
    function onEnter() {
        openMarkerItem(item);
    }

    function onLeave() {
        closeMarkerItem(item);
        //console.log("sfsdfsfsdfsdf111111111");
    }

    //isMobile

    return (
        <div className='itinerary__item__view__wrapper' css={style.itinerary__item__view__wrapper}>
            <div className='itinerary__item__view__index' css={style.itinerary__item__view__index}>
                <div className='itinerary__view__index__item__small' css={style.itinerary__view__index__item__small}>
                    <div className='itinerary__view__index__item__inner__small'></div>
                </div>
            </div>
            {
                isMobile() ?
                    <div className='itinerary__item__view__detail__small' css={style.itinerary__item__view__detail__small} onClick={onEnter}>
                        <div className='itinerary__item__view__detail__title'> {item.detailItemTitle} </div>
                        <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des}>
                            {item.detailItemDes}
                        </div>
                    </div> :
                    <div className='itinerary__item__view__detail__small' css={style.itinerary__item__view__detail__small} onMouseEnter={onEnter} onMouseLeave={onLeave}>
                        <div className='itinerary__item__view__detail__title'> {item.detailItemTitle} </div>
                        <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des}>
                            {item.detailItemDes}
                        </div>
                    </div>
            }
        </div>
    )
}