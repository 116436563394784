import { variables } from 'cssInJs'

export default{
    
    formStyle : {
        'hr' : {
            marginTop: '0px'
        },
        'input.form-check-input' : {
            marginTop:'0.45rem'
        },
    },
    actions : {
        'button' : {
            backgroundColor: variables.orangeColor,
            '&:hover' : {
                backgroundColor: variables.dkOrangeColor,
                borderColor: variables.dkOrangeColor
            }
        }
    },
    titleStyle :{
        'h4' : {
            display : 'inline-block'
        }
    },
    colStyle :{
        '.ff--checkbox.form-group label.label' : {
            display: 'none'
        },
        '.ff--checkbox.form-group .controlOptions' : {
            display: 'flex',
            flexWrap: 'wrap',
            '.form-check-inline' : {
                flexBasis: '48%',
                maxWidth: '48%',
                alignItems: 'baseline'
            }
        }
    }
}