/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './bannerSearchStyle';
import { BsSearch } from "react-icons/bs";
import { useState } from "react";
import { useRouter } from '../../components';

export function BannerSearch(){

    const [keyword, setKeyword ] = useState('');
    const { navigate } = useRouter();
    
    function search(e) {
        e.preventDefault();
        navigate('/search-results?q=' + encodeURIComponent(keyword))
    }
    function onChange(e) {
        setKeyword(e.target.value);
    }

    return(
        <div css={style.container}>
            <div css={style.imgContainer}>
                <img src="/assets/flow-device-transparent.png" style={{width:'100%'}} alt="wave"/>
            </div>
            <div css={style.box}>
                <div css={style.textBox}>
                    <div>
                        <div css={style.flowTest}> G </div>
                        <div css={style.flowTest}> O </div>
                        <div css={style.flowTest}> O </div>
                        <div css={style.flowTest}> D </div>
                    </div>
                    <div>
                        <div css={style.flowTest}> T </div>
                        <div css={style.flowTest}> I </div>
                        <div css={style.flowTest}> M </div>
                        <div css={style.flowTest}> E </div>
                        <div css={style.flowTest}> S </div>
                    </div>
                    <div>
                        <div css={style.flowTest}> F </div>
                        <div css={style.flowTest}> L </div>
                        <div css={style.flowTest}> O </div>
                        <div css={style.flowTest}> W </div>
                    </div>
                </div>
                <form onSubmit={(e) => search(e)}>
                <div css={style.input} className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                        <BsSearch />
                    </span>
                    <input type="text" onChange={onChange} className="form-control" value={keyword} placeholder="Find your good times here" aria-label="Search" aria-describedby="basic-addon1" />
                </div>
                </form>
            </div>
            <div css={style.imgContainer}>
                <img src="/assets/flow-device-transparent.png" style={{width:'100%'}} alt="wave"/>
            </div>
        </div>
    )
}