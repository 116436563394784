import { variables, mq } from '../../cssInJs'

export default {
    container : mq({
        position: 'absolute',
        display: 'block',
        top: '52%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        backgroundColor: 'rgba(0,0,0,0.4)',
        maxWidth: ['310px',null,'270px','400px',null,'440px'],
        width: '100%'
    }),
    imgContainer:{
        width: '100%'
    },
    box : {
        color: variables.sandCtoneColor,
        padding: '1rem 1rem',
    },
    textBox : mq({
        textAlign: 'center',
        fontWeight: 600,
        fontSize: ['4.5rem',null,'3.5rem','4rem',null,'5rem'],
        lineHeight: '1',
        //letterSpacing: '6px'
    }),
    input : mq({
        marginBottom: '0rem !important',
        marginTop: '1rem',
        width: ['94%',null,null,'90%'],
        marginLeft: 'auto',
        marginRight: 'auto',
        '.form-control' : mq({
            height: 'unset',
            lineHeight: '1',
            fontSize: ['1rem',null,null,'0.8rem'],
            borderRadius:'0',
            backgroundColor: 'rgba(255,255,255,0.8)',
            border: 'none'
        }),
        '.input-group-text' : {
            lineHeight: '1',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            backgroundColor: variables.orangeColor,
            border: 'none',
            borderRadius: '0',
            'svg' : {
                color: 'white'
            }
        }
    }),
    flowTest : {
        display: 'inline-block',
        margin: '0 0.2rem'
    }
}