
import { renderRule as atdw_map } from './atdw-map/render';
import { renderRule as atdw_map_town } from './atdw-map-town/render';
import { renderRule as atdw_search } from './atdw-search/render';
import { renderRule as code_instagram } from './code-instagram/render';
import { renderRule as code_subscribe } from './code-subscribe/render';
import { renderRule as consentForm } from './consentForm/render';
import { renderRule as html } from './html/render';
import { renderRule as imagePanel } from './imagePanel/render';
import { renderRule as pageTiles } from './pageTiles/render';
import { renderRule as panel } from './panel/render';
import { renderRule as photoGallery } from './photoGallery/render';
import { renderRule as sharingIcons } from './sharingIcons/render';
import { renderRule as signupForm } from './signupForm/render';
import { renderRule as subscribePanel } from './subscribePanel/render';
import { renderRule as videoPanel } from './videoPanel/render';
export const items = [
  atdw_map,
  atdw_map_town,
  atdw_search,
  code_instagram,
  code_subscribe,
  consentForm,
  html,
  imagePanel,
  pageTiles,
  panel,
  photoGallery,
  sharingIcons,
  signupForm,
  subscribePanel,
  videoPanel,
]