// xs,  sm,  md,  lg,  xl,  xxl     <-- only sample
import { mq, variables } from "../../../../cssInJs"

// <    576  768  992  1200 1600
export function getPanelStyle() {
  return {
    hoverPanel : mq({
      //height: '30%',
      //backgroundColor: 'red',
      position: 'absolute',
      //overflow: 'hidden',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
    }),

    hoverPanelTop3 : mq({
      height: '30%',
      position: 'relative',
      '&.sandStone' : {
        backgroundColor: variables.sandCtoneColor,
      },
      '&.blie' : {
        backgroundColor: variables.blieColor,
      },
      '&.green' : {
        backgroundColor: variables.greenColor,
      },
      '&.orange' : {
        backgroundColor: variables.orangeColor,
      },
      '&.yellow' : {
        backgroundColor: variables.yellowColor,
      }
    }),
    hoverPanelCenter3 : mq({
      height: '40%',
      position: 'relative',
      '&.sandStone' : {
        backgroundColor: variables.sandCtoneColor,
      },
      '&.blie' : {
        backgroundColor: variables.blieColor,
      },
      '&.green' : {
        backgroundColor: variables.greenColor,
      },
      '&.orange' : {
        backgroundColor: variables.orangeColor,
      },
      '&.yellow' : {
        backgroundColor: variables.yellowColor,
      }
    }),
    hoverPanelBottom3 : mq({
      height: '30%',
      position: 'relative',
      '&.sandStone' : {
        backgroundColor: variables.sandCtoneColor,
      },
      '&.blie' : {
        backgroundColor: variables.blieColor,
      },
      '&.green' : {
        backgroundColor: variables.greenColor,
      },
      '&.orange' : {
        backgroundColor: variables.orangeColor,
      },
      '&.yellow' : {
        backgroundColor: variables.yellowColor,
      }
    }),

    hoverPanelTop2 : mq({
      height: '70%',
      position: 'relative',
      // '&.sandStone' : {
      //   backgroundColor: variables.sandCtoneColor,
      // },
      // '&.blie' : {
      //   backgroundColor: variables.blieColor,
      // },
      // '&.green' : {
      //   backgroundColor: variables.greenColor,
      // },
      // '&.orange' : {
      //   backgroundColor: variables.orangeColor,
      // },
      // '&.yellow' : {
      //   backgroundColor: variables.yellowColor,
      // }
    }),
    hoverPanelBottom2 : mq({
      height: '30%',
      position: 'relative',
      // '&.sandStone' : {
      //   backgroundColor: variables.sandCtoneColor,
      // },
      // '&.blie' : {
      //   backgroundColor: variables.blieColor,
      // },
      // '&.green' : {
      //   backgroundColor: variables.greenColor,
      // },
      // '&.orange' : {
      //   backgroundColor: variables.orangeColor,
      // },
      // '&.yellow' : {
      //   backgroundColor: variables.yellowColor,
      // }
    }),

    waveColor : {
      // '&.sandStone' : {
      //   color : variables.sandCtoneColor,
      // },
      // '&.blie' : {
      //   color: variables.blieColor,
      // },
      // '&.green' : {
      //   color: variables.greenColor,
      // },
      // '&.orange' : {
      //   color: variables.orangeColor,
      // },
      // '&.yellow' : {
      //   color: variables.yellowColor,
      // }
    }

  }
}