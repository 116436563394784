const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  dkColor : '#3b3b3b',
  dkOrangeColor : '#b35128',
  dkBlueColor : '#132734',

  sandCtoneColor : '#fdf8e6',
  blieColor : '#4d868f',
  greenColor : '#85af96',
  orangeColor : '#f26c33',
  yellowColor : '#ffef9a'
  
}

const variables = {
  familyBase: '"GT Eesti Pro Text",sans-serif',
  familyHeader: '"GT Eesti Pro Text",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.dkColor,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;