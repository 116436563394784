/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Item } from '../../../../cms/items/item';

export function SubscribePanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  // console.log('item', item, subItems);
  const subItemsElts = subItems.map((subItem, index) => <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>);

  return <React.Fragment>{subItemsElts}</React.Fragment>;
}
