import { fb, validators } from '../../../../lib/form';

export function getConsentFormModel(data) {
  
  // let stateList = [
  //   {name: 'State', value: ''},
  //   {name: 'Australian Capital Territory', value: 'ACT'},
  //   {name: 'New South Wales', value: 'New South Wales'},
  //   {name: 'Victoria', value: 'Victoria'},
  //   {name: 'South Australia', value: 'South Australia'},
  //   {name: 'Queensland', value: 'Queensland'},
  //   {name: 'Western Australia', value: 'Western Australia'},
  //   {name: 'Northern Territory', value: 'Northern Territory'},
  //   {name: 'Tasmania', value: 'Tasmania'}
  // ]

  const intendedUseList = [
    {name : 'Signage', value : 'Signage'},
    {name : 'Packaging', value : 'Packaging'},
    {name : 'TV/Digital Media', value : 'TV/Digital Media'},
    {name : 'Promotional Material', value : 'Promotional Material'},
    {name : 'Other (please specify below)', value : 'Other'},
    {name : 'Web (please provide web address below)', value : 'Web'}
  ];

  const memberShipDetailsList = [
    {name : 'Riverland Industry Connector', value : 'Riverland Industry Connector'},
    {name : 'Riverland Wine', value : 'Riverland Wine'},
    {name : 'Renmark Paringa Council', value : 'Renmark Paringa Council'},
    {name : 'Loxton Waikerie Council', value : 'Loxton Waikerie Council'},
    {name : 'Berri Barmera Council', value : 'Berri Barmera Council'},
    {name : 'Mid Murray Council', value : 'Mid Murray Council'},
    {name : 'Loxton Chamber of Commerce', value : 'Loxton Chamber of Commerce'},
    {name : 'Renmark Paringa Network', value : 'Renmark Paringa Network'},
    {name : 'Riverland West Chamber of Commerce', value : 'Riverland West Chamber of Commerce'},
    {name : 'Regional Development Australia', value : 'Regional Development Australia'},
    {name : 'Other (please specify)', value : 'Other'},
  ];
  
  const model = fb.group({
    organisation: [data.organisation || '', [validators.Required()], { label: 'Organisation/Business', type: 'text' }],
    contactPerson: [data.contactPerson || '', [], { label: 'Contact Person', type: 'text' }],
    postalAddress: [data.postalAddress || '', [], { label: 'Postal Address', type: 'text' }],
    contactNumber: [data.contactNumber || '', [], { label: 'Contact Number', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    intendedUse : [data.intendedUse || [], [], { type: 'checkboxList', options: intendedUseList, style: {inline: true} }],
    intendedUseEnquiry: [data.enquiry || '', [], { label: ' ', type: 'textarea' }],

    memberShipDetails : [data.memberShipDetails || [], [], { type: 'checkboxList', options: memberShipDetailsList, style: {inline: true} }],
    memberShipDetailsEnquiry: [data.memberShipDetailsEnquiry || '', [], { label: ' ', type: 'textarea' }],
    
    accept: [false, [validators.Required('Please agree.')], { label: `I Accept`, type: 'checkbox', link : '/sites/dr5/media/pdf/riverland-brand---terms-and-conditions-of-use.pdf', linkText : 'Terms & Conditions' }]
    
    //enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea' }],
    //attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }],
    //state : [data.state || '', [], { label: null, type: 'select', options: stateList }],
  }); 
  return model;
}